@use "sass:map";
@import "../../assets/theme.scss";

.header {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .textBox {
    position: relative;
    z-index: 2;
    max-width: 90%;
    color: map.get($theme, "antiflashWhite");
    text-align: center;
  }

  .headerContent {
    margin-bottom: 40px;
    max-width: 35rem;
    margin-top: 8rem;
    h2 {
      color: map.get($theme, "antiflashWhite");
      margin-bottom: 15px;
      font-size: 3rem;
      line-height: 1.2;
    }

    p {
      color: map.get($theme, "antiflashWhite");
      font-size: 1.5rem;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .textBox {
      top: 120px;
    }

    .headerContent {
      max-width: 90%;
      margin-top: -5rem;
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.9rem;
      }
    }

    .redOverlay {
      width: 70%;
    }
  }

  @media (max-width: 480px) {
    .textBox {
      top: 90px;
    }

    .headerContent {
      h2 {
        font-size: 1.4rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }
}
.scrollIndicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow {
  width: 20px;
  height: 20px;
  border-right: 2px solid map.get($theme, "yinmnRed");
  border-bottom: 2px solid map.get($theme, "yinmnRed");
  transform: rotate(45deg);
  margin: 5px;
  animation: bounce 1.5s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(10px) rotate(45deg);
  }
  60% {
    transform: translateY(5px) rotate(45deg);
  }
}

/* Services Section */
.servicesSection {
  padding: 4rem 2rem;
  background-color: #f8f8f8;
  margin-bottom: 4rem;

  .heading {
    text-align: center;
    font-size: 2rem;
    color: map.get($theme, "yinmnRed");
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .description {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }

    @media (max-width: 480px) {
      gap: 1rem;
    }
  }

  .serviceItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 250px;
    padding: 1.5rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    .icon {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: map.get($theme, "yinmnRed");
    }

    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      color: #666;
      font-weight: 400;
    }
  }
}

/* About Section */
.infoSection {
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f8f8f8;

  .content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    text-align: center;
  }

  .left,
  .right {
    flex: 1;
    margin: 1rem;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: bold;
    color: map.get($theme, "yinmnRed");
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1.2;
    color: #001829;
  }

  .underline {
    width: 50px;
    height: 4px;
    background-color: map.get($theme, "yinmnRed");
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .right p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1.5rem;
  }
}

.aboutSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem;

  .textContainer {
    flex: 1;
    padding-right: 2rem;

    h2 {
      font-size: 2rem;
      color: map.get($theme, "yinmnRed");
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      color: #333;
      line-height: 1.6;
      margin-bottom: 1rem;
    }
  }

  .imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100%;
    }

    .ownerName {
      margin-top: 1rem;
      font-size: 1rem;
      color: #666;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    .textContainer {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  }
}

/* Two Photos Section */
.container {
  display: flex;
  justify-content: center;

  .customerOption {
    flex: 0.8;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: map.get($theme, "antiflashWhite");
    padding: 1rem;
    text-align: center;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    height: 80vh;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      transition: background 0.3s ease;
    }

    .content {
      position: relative;
      z-index: 2;
      opacity: 1;
      color: map.get($theme, "antiflashWhite");
      transition: opacity 0.3s ease;

      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: inherit;
      }

      p {
        font-size: 1.2rem;
        color: inherit;
      }
    }

    &:hover .overlay {
      background: rgba(255, 0, 0, 0.7);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .customerOption {
      height: auto;
      padding: 3rem 1rem;
    }

    .content h2 {
      font-size: 1.5rem;
    }

    .content p {
      font-size: 1rem;
    }
  }
}

/* Map Section */
.mapContainer {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: map.get($theme, "antiflashWhite");

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    position: relative;
  }

  .mapOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .mapText {
    position: absolute;
    z-index: 2;
    color: map.get($theme, "antiflashWhite");
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    height: 300px;

    .mapText {
      font-size: 1rem;
      padding: 8px 15px;
    }
  }
}
